import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { IllnessesCovered } from 'common/components/templates/IllnessesCovered'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface IllnessesCoveredPageProps {
    pageContext: any
}

export const IllnessesCoveredPage: React.FC<IllnessesCoveredPageProps> = ({
    pageContext
}: any) => {
    return <IllnessesCovered {...getIllnessedCovered(pageContext)} />
}

export default IllnessesCoveredPage

export const getIllnessedCovered = (data: any) => ({
    layout: getLayoutData(data),
    jumbotron: getJumbotronData(data),
    illnessList: {
        title: data.heading,
        text: data.sub_heading,
        list: data.illness
    }
})
