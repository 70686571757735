import { Box, Grid, makeStyles } from '@material-ui/core'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { useBrowser } from 'common/hooks/useBrowser'
import { useDesktop } from 'common/hooks/useDesktop'
import React, { useState } from 'react'

export interface CoverListProps {
    title: string
    text: string
    list: { text: string; title: string }[]
}

const letters = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
]

export const CoverList: React.FC<CoverListProps> = ({ title, text, list }) => {
    const desktop = useDesktop()
    const [cureentLetter, setCureentLetter] = useState<string>('')
    const useStyles = makeStyles({
        active: {
            borderBottom: '2px solid #1D2F7C',
            paddingBottom: 5
        },
        letter: {
            width: desktop ? 'auto' : '12.5%'
        }
    })
    const classes = useStyles()
    const isBrowser = useBrowser()

    const handleClick = (e: any, letter: string) => {
        e.preventDefault()
        setCureentLetter(letter)
        let el: any = isBrowser && document.getElementById(letter)
        if (el !== null) {
            el.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }
    return (
        <Grid data-testid='CoverList' container>
            <Grid item xs={12} md={3}>
                <Text label={title} variant='h4' />
                <Text box={{ color: '#766F90', mt: 6 }} label={text} />
            </Grid>
            <Grid item md={2} />
            <Grid item xs={12} md={7}>
                {!desktop && <Spacer variant='sm' />}
                <div
                    style={{
                        marginBottom: 30,
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: desktop ? 'space-between' : 'normal',
                        fontSize: '16px',
                        fontWeight: 500
                    }}
                >
                    {letters.map(letter => {
                        return isBrowser && document.getElementById(letter) ? (
                            <div
                                key={letter}
                                style={{
                                    width: desktop ? 'auto' : '12.5%',
                                    marginBottom: 20
                                }}
                            >
                                <a
                                    data-testid='Link'
                                    className={
                                        cureentLetter === letter
                                            ? classes.active
                                            : ''
                                    }
                                    href={`#${letter}`}
                                    onClick={e => handleClick(e, letter)}
                                >
                                    {letter}
                                </a>
                            </div>
                        ) : (
                            <div
                                key={letter}
                                style={{
                                    width: desktop ? 'auto' : '12.5%',
                                    marginBottom: 20
                                }}
                            >
                                <span
                                    style={{
                                        color: '#C1C7E1',
                                        marginBottom: 5
                                    }}
                                >
                                    {letter}
                                </span>
                            </div>
                        )
                    })}
                </div>
                {!desktop && <Spacer variant='md' />}
                {list &&
                    list
                        .sort((a, b) => (a.title > b.title ? 1 : -1))
                        .map((listItem, index) => (
                            <div
                                id={`${listItem.title[0]}`}
                                style={{ scrollMargin: 100 }}
                                key={index}
                            >
                                <Box
                                    borderTop='1px solid #d5d9eb'
                                    display='flex'
                                    flexDirection='column'
                                    height={166}
                                    key={listItem.title}
                                >
                                    <Box display='flex' height={48}>
                                        {list[index - 1]?.title[0] !==
                                            listItem.title[0] && (
                                            <>
                                                <Box flex={1} />
                                                <Text
                                                    box={{
                                                        alignItems: 'center',
                                                        bgcolor: '#50C9B5',
                                                        color: '#fff',
                                                        display: 'flex',
                                                        fontWeight: 'bold',
                                                        justifyContent:
                                                            'center',
                                                        height: 48,
                                                        width: 48
                                                    }}
                                                    label={listItem.title[0]}
                                                    variant='h2'
                                                />
                                            </>
                                        )}
                                    </Box>
                                    <Box flex={1} marginTop={desktop ? 0 : 3}>
                                        <Text
                                            label={listItem.title}
                                            variant='h3'
                                            style={{ fontSize: 20 }}
                                        />
                                        <Text
                                            box={{ mt: 3, color: '#9D98B0' }}
                                            label={listItem.text}
                                            variant='body2'
                                            html={true}
                                        />
                                    </Box>
                                    <Box height={48} />
                                </Box>
                            </div>
                        ))}
            </Grid>
        </Grid>
    )
}
