import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import {
    CoverList,
    CoverListProps
} from 'common/components/organisms/CoverList'
import {
    Jumbotron,
    JumbotronProps
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import React from 'react'

export interface IllnessesCoveredProps {
    illnessList: CoverListProps
    jumbotron: JumbotronProps
    layout: LayoutProps
}

export const IllnessesCovered: React.FC<IllnessesCoveredProps> = ({
    jumbotron,
    layout,
    illnessList
}) => {
    return (
        <Layout {...layout}>
            <Jumbotron {...jumbotron} />
            <Spacer variant='md' />
            <Wrapper component='section'>
                <CoverList {...illnessList} />
            </Wrapper>
        </Layout>
    )
}
